@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.input-phone-number .PhoneInputInput{
  background-color: transparent;
}

.input-phone-number input:focus{
  outline: none !important;
  border:none !important;
  box-shadow: none !important;
}

.popcorn-container {
}

.popcorn {
  position: absolute;
  width: 50px; /* Adjust size as needed */
  height: 50px;
  background-size: cover;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #E6CEE5 inset;
  transition: background-color 5000s ease-in-out 0s;
}
